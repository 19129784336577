<template>
  <div>
    <div
      v-loading="contentLoading"
      class="body"
      style="width: 1080px;margin: 0 auto;padding:10px;border: solid 1px #DCDFE6">
      <h2 align="center">{{$t("elevator.elevatorCheck")}}</h2>
      <div style="padding: 30px 0px 40px 80px;border: solid 1px #DCDFE6">
        <el-row :gutter="20" class="elEow">
          <el-col :span="6">
            <div><span>{{$t("elevator.name")}}：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{elevator.name}}</span></div>
          </el-col>
          <el-col :span="1">
          </el-col>
          <el-col :span="6">
            <div><span>{{$t("elevator.no")}}：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{elevator.no}}</span></div>
          </el-col>

          <el-col :span="1">
          </el-col>
          <el-col :span="6">
            <div>
              <span>{{$t("elevator.floors")}}：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{elevator.elevatorFloors.length}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="elEow">
          <el-col :span="6">
            <div><span>{{$t("elevator.dtuCode")}}：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{elevator.dtuCode}}</span></div>
          </el-col>
          <el-col :span="1">
          </el-col>
          <el-col :span="6">
            <div>
              <span>{{$t("elevator.nodeCode")}}：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{elevator.nodeCode}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-button
              size="medium"
              type="primary"
              :loading="btn1Status"
              :disabled="btn1Disabled"
              @click="handleBtn1()">
              {{btn1Text}}
            </el-button>
            <div class="elEow"><span>消息接收时间：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{m0.messageDateTime}}</span></div>
            <div class="elEow"><span>测试结果：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{m0.testResult}}&nbsp;%</span></div>
            <div class="elEow">
              <span>最小力矩作用下的位移量：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{m0.displacement}}&nbsp;mm</span>
            </div>
            <div class="elEow">
              <span>第二抱闸力矩测试合格标准：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{m0.secondTestResult}}&nbsp;mm</span>
            </div>
          </el-col>
          <el-col :span="15">
            <el-button
              size="medium"
              type="primary"
              :loading="btn5Status"
              :disabled="btn5Disabled"
              @click="handleBtn5()">
              {{btn5Text}}
            </el-button>
            <table class="gridtable" style="margin-top: 10px;">
              <tr>
                <th>起始楼层</th>
                <th>目的楼层</th>
                <th>运行方向</th>
                <th>原始值(mm)</th>
                <th>本层值(mm)</th>
              </tr>
              <tr v-for="(item, index) of m4" :key="index">
                <td><span>{{item.beginFloor}}</span></td>
                <td><span>{{item.endFloor}}</span></td>
                <td><span>{{item.direction === 1 ? "上行" : "下行"}}</span></td>
                <td><span>{{item.primitiveValue}}</span></td>
                <td><span>{{item.testValue}}</span></td>
              </tr>
            </table>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="elEow">
          <el-col :span="8">
            <el-button
              size="medium"
              type="primary"
              :loading="btn2Status"
              :disabled="btn2Disabled"
              @click="handleBtn234(1)">
              {{btn2Text}}
            </el-button>
          </el-col>
          <el-col :span="8">
            <el-button
              size="medium"
              type="primary"
              :loading="btn3Status"
              :disabled="btn3Disabled"
              @click="handleBtn234(2)">
              {{btn3Text}}
            </el-button>
          </el-col>
          <el-col :span="8">
            <el-button
              size="medium"
              type="primary"
              :loading="btn4Status"
              :disabled="btn4Disabled"
              @click="handleBtn234(3)">
              {{btn4Text}}
            </el-button>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="elEow">
          <el-col :span="8">
            <div><span>数据接收时间：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{messageDateTimeM1}}</span></div>
          </el-col>
          <el-col :span="8">
            <div><span>数据接收时间：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{messageDateTimeM2}}</span></div>
          </el-col>
          <el-col :span="8">
            <div><span>数据接收时间：</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>{{messageDateTimeM3}}</span></div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="elEow">
          <el-table :data="dataListToTable" style="width: 800px" border>
            <table-column prop="floor" label="楼层" width="200px"></table-column>
            <table-column prop="position" label="运行位置（dm）" width="200px"></table-column>
            <table-column prop="currentValue" label="电流值" width="200px"></table-column>
            <table-column prop="stayTest" label="停靠次数" width="200px"></table-column>
          </el-table>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  const moduleName = "elevators";
  const moduleName_remote = "remote";
  const REQUEST_TIME = 60; // 请求的倒计时时间

  export default {
    data() {
      return {
        contentLoading: false,
        requestPiece: 1,
        countdownSeconds: REQUEST_TIME,
        noticeFlag: true,
        btn1Disabled: false,
        btn1Status: false,
        btn1Text: "获取抱闸力矩测试结果",
        btn2Disabled: false,
        btn2Status: false,
        btn2Text: "查询电梯实时运行位置",
        btn3Disabled: false,
        btn3Status: false,
        btn3Text: "查询电梯实时电流值",
        btn4Disabled: false,
        btn4Status: false,
        btn4Text: "查询电梯各层站的停靠次数",
        btn5Disabled: false,
        btn5Status: false,
        btn5Text: "查询曳引力数据",
        elevator: {
          id: 0,
          no: "",
          name: "",
          dtuCode: "",
          nodeCode: "",
          elevatorFloors: [],
        },
        m0: {
          messageDateTime: "-",
          testResult: "-",
          displacement: "-",
          secondTestResult: "-",
        },
        m1: {
          messageDateTime: "-",
          startFloor: "",
          dataList: [],
          type: -1,
        },
        m4: [],
        messageDateTimeM1: "-",
        messageDateTimeM2: "-",
        messageDateTimeM3: "-",
        dataListToTable: [],
        m1List: [],
        m2List: [],
        m3List: [],
      };
    },
    mounted() {
      this.elevator.id = this.$route.params.elevatorId;
      this.getData();
    },
    methods: {
      getData() {
        this.$api.getById(moduleName, this.elevator.id).then(res => {
          this.elevator = Object.assign(this.elevator, res.data);
          this.contentLoading = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      btn1CountDownLoop() {
        if (this.countdownSeconds === 0) {
          this.btn1Status = false;
          this.btn2Disabled = false;
          this.btn3Disabled = false;
          this.btn4Disabled = false;
          this.btn1Text = "获取抱闸力矩测试结果";
          this.countdownSeconds = REQUEST_TIME;
        } else {
          this.btn1Status = true;
          this.btn2Disabled = true;
          this.btn3Disabled = true;
          this.btn4Disabled = true;
          this.btn1Text = "指令已发送（" + this.countdownSeconds + "秒倒计时）";
          this.countdownSeconds--;
          setTimeout(() => {
            this.btn1CountDownLoop();
          }, 1000);
        }
      },
      handleBtn5() {
        let params = {
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
        };
        this.btn5Status = true;
        this.$api.getData(moduleName_remote + "/messagePushAndGet", params).then(res => {
          console.log(res.data);
          if (res.data) {
            this.btn5Status = false;
            this.$message.success("成功");
            this.m4 = Object.assign(this.m4, res.data);
          }
          this.btn5Status = false;
        }).catch(() => {
          this.btn5Status = false;
        });
      },
      handleBtn1() {
        if (this.countdownSeconds === REQUEST_TIME) { // 循环中只执行一次
          let params = {
            "type": 0,
            "requestPiece": 0,
            "dtuCode": this.elevator.dtuCode,
            "nodeCode": this.elevator.nodeCode,
          };
          this.$api.getData(moduleName_remote + "/messagePush", params).then(res => {
          }).catch(() => {
            this.$message.error("检测通知失败");
          });
        }
        let params = {
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
        };
        this.$api.getData(moduleName_remote + "/M0Data", params).then(res => {
          this.countdownSeconds = 0;
          this.$message.success("成功");
          this.m0 = Object.assign(this.m0, res.data);
        }).catch(error => {
          if (error.response.data.code === 1) {
            let loop = setTimeout(() => {
              this.handleBtn1();
            }, 1000);
            if (this.countdownSeconds < 3) { // 提前3秒结束请求，防止倒计时的时间已经结束回到初始值（60s），而此请求因为网络阻塞无法执行结束循环的方法
              clearTimeout(loop);
              this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
            }
          }
        });
        if (this.countdownSeconds === REQUEST_TIME) { // 循环中只执行一次
          this.btn1CountDownLoop();
        }
      },
      handleBtn234(type) {
        console.log("........type:" + type + "........m1.type:" + this.m1.type + ", ........requestPiece:" + this.requestPiece);
        if (this.noticeFlag) { // 每一段请求在循环中只执行一次
          console.log("发送指令......");
          this.noticeFlag = false;
          let params = {
            "type": type,
            "requestPiece": this.requestPiece,
            "dtuCode": this.elevator.dtuCode,
            "nodeCode": this.elevator.nodeCode,
          };
          this.$api.getData(moduleName_remote + "/messagePush", params).then().catch(() => {
            this.$message.error("检测通知失败");
            this.m1.type = -1;
          });
        }
        let params = {
          "dtuCode": this.elevator.dtuCode,
          "nodeCode": this.elevator.nodeCode,
          "type": type,
        };
        this.$api.getData(moduleName_remote + "/M123Data", params).then(res => {
          // 返回的第一段数据：1，不存在，<=20层，赋值展示；2，不存在，>20层，赋值，继续获取；3，存在，抛弃，>20层，继续获取；
          if (this.m1.type === -1 && res.data.startFloor === 0 && this.requestPiece === 1) { // 返回的第一段数据
            console.log("..........................................返回第一段");
            if (this.m1.type !== type) { // 表示第一段不存在，可以赋值。楼层小于20赋值完结束；楼层大于20赋值完请求第二段
              this.m1 = Object.assign(this.m1, res.data);
              if (type === 1) { // 初始化清空list数据
                this.m1List = [];
              } else if (type === 2) {
                this.m2List = [];
              } else if (type === 3) {
                this.m3List = [];
              }
              this.m1.type = type;
              if (this.elevator.elevatorFloors.length <= 20) { // 如果总楼层小于等于20层赋值展示结束
                // 赋值开始
                if (type === 1) {
                  this.messageDateTimeM1 = this.m1.messageDateTime;
                } else if (type === 2) {
                  this.messageDateTimeM2 = this.m1.messageDateTime;
                } else if (type === 3) {
                  this.messageDateTimeM3 = this.m1.messageDateTime;
                }
                for (let item in this.m1.dataList) {
                  if (type === 1) {
                    this.m1List.push(this.m1.dataList[item]);
                  } else if (type === 2) {
                    this.m2List.push(this.m1.dataList[item]);
                  } else if (type === 3) {
                    this.m3List.push(this.m1.dataList[item]);
                  }
                  this.refreshDataListToTable();
                }
                this.$message.success("成功");
                // 赋值结束
                // ..........重新初始化
                this.m1.type = -1;
                this.requestPiece = 1;
                this.countdownSeconds = 0; // 结束请求
                this.noticeFlag = true;
                // ..........重新初始化
              } else { // 如果总楼层大于20层先赋值再继续获取第二个数据包
                // 赋值开始
                for (let item in this.m1.dataList) {
                  if (type === 1) {
                    this.m1List.push(this.m1.dataList[item]);
                  } else if (type === 2) {
                    this.m2List.push(this.m1.dataList[item]);
                  } else if (type === 3) {
                    this.m3List.push(this.m1.dataList[item]);
                  }
                }
                // 赋值结束
                console.log("..........................................第一段完成");
                console.log(this.m1.dataList);
                console.log("..........................................开始第二段请求");
                this.requestPiece = 2;
                this.noticeFlag = true; // 准备获取第二段，开启messagePush方法调用
                this.handleBtn234(type);// 开始读取第二段
              }
            }
          } else if (this.m1.type !== -1 && res.data.startFloor === 20 && this.requestPiece === 2) { // 返回的第二段数据：1，不存在，<=40层，赋值展示；2，不存在，>40层，赋值，继续获取；3，存在，抛弃，>40层，继续获取；
            console.log("..........................................返回第二段");
            if (this.m1.startFloor !== 20) { // 表示第二段不存在，可以赋值。楼层小于40赋值完结束；楼层大于40赋值完请求第二段
              this.m1 = Object.assign(this.m1, res.data);
              if (this.elevator.elevatorFloors.length <= 40) { // 如果总楼层小于等于40层赋值展示结束
                // 赋值开始
                if (type === 1) {
                  this.messageDateTimeM1 = this.m1.messageDateTime;
                } else if (type === 2) {
                  this.messageDateTimeM2 = this.m1.messageDateTime;
                } else if (type === 3) {
                  this.messageDateTimeM3 = this.m1.messageDateTime;
                }
                for (let item in this.m1.dataList) {
                  if (type === 1) {
                    this.m1List.push(this.m1.dataList[item]);
                  } else if (type === 2) {
                    this.m2List.push(this.m1.dataList[item]);
                  } else if (type === 3) {
                    this.m3List.push(this.m1.dataList[item]);
                  }
                  this.refreshDataListToTable();
                }
                this.$message.success("成功");
                // 赋值结束
                // ..........重新初始化
                this.m1.type = -1;
                this.requestPiece = 1;
                this.countdownSeconds = 0; // 结束请求
                this.noticeFlag = true;
                // ..........重新初始化
              } else { // 如果总楼层大于40层先赋值再继续获取第三个数据包
                // 赋值开始
                for (let item in this.m1.dataList) {
                  if (type === 1) {
                    this.m1List.push(this.m1.dataList[item]);
                  } else if (type === 2) {
                    this.m2List.push(this.m1.dataList[item]);
                  } else if (type === 3) {
                    this.m3List.push(this.m1.dataList[item]);
                  }
                }
                // 赋值结束
                console.log("..........................................第二段完成");
                console.log(this.m1.dataList);
                console.log("..........................................开始第三段请求");
                this.requestPiece = 3;
                this.noticeFlag = true; // 准备获取第三段，开启messagePush方法调用
                this.handleBtn234(type);// 开始读取第三段
              }
            }
          } else if (this.m1.type !== -1 && res.data.startFloor === 40 && this.requestPiece === 3) { // 返回的第三段数据：1，不存在，<=60层，赋值展示；2，不存在，>60层，赋值，继续获取；3，存在，抛弃，>60层，继续获取；
            console.log("..........................................返回第三段");
            if (this.m1.startFloor !== 40) { // 表示第三段不存在，可以赋值。楼层小于60赋值完结束；楼层大于60赋值完请求第四段
              this.m1 = Object.assign(this.m1, res.data);
              if (this.elevator.elevatorFloors.length <= 40) { // 如果总楼层小于等于60层赋值展示结束
                // 赋值开始
                if (type === 1) {
                  this.messageDateTimeM1 = this.m1.messageDateTime;
                } else if (type === 2) {
                  this.messageDateTimeM2 = this.m1.messageDateTime;
                } else if (type === 3) {
                  this.messageDateTimeM3 = this.m1.messageDateTime;
                }
                for (let item in this.m1.dataList) {
                  if (type === 1) {
                    this.m1List.push(this.m1.dataList[item]);
                  } else if (type === 2) {
                    this.m2List.push(this.m1.dataList[item]);
                  } else if (type === 3) {
                    this.m3List.push(this.m1.dataList[item]);
                  }
                  this.refreshDataListToTable();
                }
                this.$message.success("成功");
                // 赋值结束
                // ..........重新初始化
                this.m1.type = -1;
                this.requestPiece = 1;
                this.countdownSeconds = 0; // 结束请求
                this.noticeFlag = true;
                // ..........重新初始化
              } else { // 如果总楼层大于60层先赋值再继续获取第四个数据包
                // 赋值开始
                for (let item in this.m1.dataList) {
                  if (type === 1) {
                    this.m1List.push(this.m1.dataList[item]);
                  } else if (type === 2) {
                    this.m2List.push(this.m1.dataList[item]);
                  } else if (type === 3) {
                    this.m3List.push(this.m1.dataList[item]);
                  }
                }
                // 赋值结束
                console.log("..........................................第三段完成");
                console.log(this.m1.dataList);
                console.log("..........................................开始第四段请求");
                this.requestPiece = 4;
                this.noticeFlag = true; // 准备获取第四段，开启messagePush方法调用
                this.handleBtn234(type);// 开始读取第四段
              }
            }
          } else if (this.m1.type !== -1 && res.data.startFloor === 60 && this.requestPiece === 4) { // 返回的第四段数据：总共支持64层，直接赋值展示；
            console.log("..........................................第四段完成");
            this.m1 = Object.assign(this.m1, res.data);
            // 赋值开始
            if (type === 1) {
              this.messageDateTimeM1 = this.m1.messageDateTime;
            } else if (type === 2) {
              this.messageDateTimeM2 = this.m1.messageDateTime;
            } else if (type === 3) {
              this.messageDateTimeM3 = this.m1.messageDateTime;
            }
            for (let item in this.m1.dataList) {
              if (type === 1) {
                this.m1List.push(this.m1.dataList[item]);
              } else if (type === 2) {
                this.m2List.push(this.m1.dataList[item]);
              } else if (type === 3) {
                this.m3List.push(this.m1.dataList[item]);
              }
              this.refreshDataListToTable();
            }
            this.$message.success("成功");
            // 赋值结束
            // ..........重新初始化
            this.m1.type = -1;
            this.requestPiece = 1;
            this.countdownSeconds = 0; // 结束请求
            this.noticeFlag = true;
            // ..........重新初始化
          } else { // 重复数据抛弃继续请求
            console.log("..........................................重复数据");
            console.log(res.data);
            console.log("..........................................继续请求");
            if ((this.countdownSeconds > 17 && this.countdownSeconds < 20) || (this.countdownSeconds > 37 && this.countdownSeconds < 40)) { // 再发一次指令
              this.noticeFlag = true;
            }
            let loop = setTimeout(() => {
              this.handleBtn234(type);
            }, 1000);
            if (this.countdownSeconds < 3) { // 提前3秒结束请求，防止倒计时的时间已经结束回到初始值（60s），而此请求因为网络阻塞无法执行结束循环的方法
              clearTimeout(loop);
              this.$message.error(this.$t("common.tip.getDataError"));
              // ..........重新初始化
              this.m1.type = -1;
              this.requestPiece = 1;
              this.noticeFlag = true;
              // ..........重新初始化
            }
          }
        }).catch(error => {
          if (error.response.data.code === 1) {
            if ((this.countdownSeconds > 17 && this.countdownSeconds < 20) || (this.countdownSeconds > 37 && this.countdownSeconds < 40)) { // 再发一次指令
              this.noticeFlag = true;
            }
            let loop = setTimeout(() => {
              this.handleBtn234(type);
            }, 1000);
            if (this.countdownSeconds < 3) { // 提前3秒结束请求，防止倒计时的时间已经结束回到初始值（60s），而此请求因为网络阻塞无法执行结束循环的方法
              clearTimeout(loop);
              this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
              // ..........重新初始化
              this.m1.type = -1;
              this.requestPiece = 1;
              this.noticeFlag = true;
              // ..........重新初始化
            }
          }
        });
        if (this.countdownSeconds === REQUEST_TIME) { // 循环中只执行一次
          if (type === 1) {
            this.btn2CountDownLoop();
          } else if (type === 2) {
            this.btn3CountDownLoop();
          } else if (type === 3) {
            this.btn4CountDownLoop();
          }
        }
      },
      refreshDataListToTable() {
        this.dataListToTable = [];
        for (let i = 0; i < this.elevator.elevatorFloors.length; i++) {
          let item = {
            "floor": this.elevator.elevatorFloors[i].displayValue,
            "position": this.m1List[i],
            "currentValue": this.m2List[i],
            "stayTest": this.m3List[i],
          };
          this.dataListToTable.push(item);
        }
      },
      btn2CountDownLoop() {
        if (this.countdownSeconds === 0) {
          this.btn2Status = false;
          this.btn1Disabled = false;
          this.btn3Disabled = false;
          this.btn4Disabled = false;
          this.btn2Text = "查询电梯实时运行位置";
          this.countdownSeconds = REQUEST_TIME;
        } else {
          this.btn2Status = true;
          this.btn1Disabled = true;
          this.btn3Disabled = true;
          this.btn4Disabled = true;
          this.btn2Text = "指令已发送（" + this.countdownSeconds + "秒倒计时）";
          this.countdownSeconds--;
          setTimeout(() => {
            this.btn2CountDownLoop();
          }, 1000);
        }
      },
      btn3CountDownLoop() {
        if (this.countdownSeconds === 0) {
          this.btn3Status = false;
          this.btn1Disabled = false;
          this.btn2Disabled = false;
          this.btn4Disabled = false;
          this.btn3Text = "查询电梯实时电流值";
          this.countdownSeconds = REQUEST_TIME;
        } else {
          this.btn3Status = true;
          this.btn1Disabled = true;
          this.btn2Disabled = true;
          this.btn4Disabled = true;
          this.btn3Text = "指令已发送（" + this.countdownSeconds + "秒倒计时）";
          this.countdownSeconds--;
          setTimeout(() => {
            this.btn3CountDownLoop();
          }, 1000);
        }
      },
      btn4CountDownLoop() {
        if (this.countdownSeconds === 0) {
          this.btn4Status = false;
          this.btn1Disabled = false;
          this.btn2Disabled = false;
          this.btn3Disabled = false;
          this.btn4Text = "查询电梯各层站的停靠次数";
          this.countdownSeconds = REQUEST_TIME;
        } else {
          this.btn4Status = true;
          this.btn1Disabled = true;
          this.btn2Disabled = true;
          this.btn3Disabled = true;
          this.btn4Text = "指令已发送（" + this.countdownSeconds + "秒倒计时）";
          this.countdownSeconds--;
          setTimeout(() => {
            this.btn4CountDownLoop();
          }, 1000);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
span {
  color: #606266;
  font-size: 14px;
}

.elEow {
  margin-top: 20px;
  margin-bottom: 20px;
}

table.gridtable {
  font-family: verdana, arial, sans-serif;
  font-size: 11px;
  color: #333333;
  border-width: 1px;
  border-color: #dddddd;
  border-collapse: collapse;
  width: 90%;
}

table.gridtable th {
  text-align: center;
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #dddddd;
  background-color: #D1E6F5;
}

table.gridtable td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #dddddd;
}
</style>
